<template>
	<div :class="'MapContainer '+ `show`+this.$store.state.selectedProgram.programIcon.fields.title" id="MapContainer">
		<SvgPanZoom
			style="width: 1000px; height: 1000px;"
			:zoomEnabled="true"
			:controlIconsEnabled="false"
			:fit="false"
			:center="true"
		>
			<MapSvg @pin-click="iconSelected($event)" @pin-hover="iconHover($event)"
				@pin-leave="iconLeave($event)" @star-hover="starHover($event)"  class="MapSvg" minZoom="0.1" />
			
		</SvgPanZoom>
	</div>
</template>
<script>
import SvgPanZoom from "vue-svg-pan-zoom";
import MapSvg from './MapSvg.vue';

export default {
	components: {
		SvgPanZoom,
		MapSvg
	},
	data: function(){
		return {
			usedEvent: [],
			mouseX: 0,
			mouseY: 0, 
			programs: ["Aviation", "Manufacturing","Healthcare","Design", "GeneralEd", "BusinessIT", "PoliceScience"  ],
			nonreactiveMapElements: ["Layer_16", "Group_126","Group_126-1","Group_126-2","Group_126-3","Group_126-4", "Group_126-6", "Group_126-7", "Group_126-8", "Group_126-9"  ],
			currentColor: '#E6E7E8',
			iconHoverContent: ''
		};
	},
	mounted: function(){
		window.addEventListener('mousemove',this.mouseLocation);
	},
	methods: {
		iconSelected: function(event){
			// strip id's of numbers
			let strippedEvent = event.replace(/[0-9]/g, '');
			// filter out event data that doens't match programs
			if (!this.programs.includes(strippedEvent)){
				return;
			} else{
				var contentful = require('contentful'),
					contentfulConfig = require('../../.contentful.json'),
					client = contentful.createClient({
						space: contentfulConfig.CTF_SPACE_ID,
						accessToken: contentfulConfig.CTF_CDA_ACCESS_TOKEN
					});
				// Search enteries by content type
				client.getEntries({content_type: 'programLab'})
					.then((res) => {
						// loop to see if any 'mapId' match those found in programLab
						for(let i = 0; i < res.items.length; i++){
							if(event != res.items[i].fields.mapId){
								continue;
							} else if(event == res.items[i].fields.mapId){
								// if a match is found commit it to the store
								this.$store.commit('setSelectedIconLab', res.items[i].fields);
								return;
							}
						}
					});		
			}
		},
		iconHover: function(event){
			// 1. test if icon contains event
			// 2. if this event as already been added to the div or if the element is nonreactive
			if(/\d/.test(event) && !(this.usedEvent.includes(event) || this.nonreactiveMapElements.includes(event))){
				this.iconContent(event);
				this.usedEvent.push(event);

				// add div
				let docBody = document.querySelector("body"),
					divElement = document.createElement("div"),
					divContent = document.createTextNode(this.iconHoverContent);
				divElement.id = "iconHover";
				divContent.id = "iconContent";
				divElement.style.left = `${this.mouseX - 60}px`;
				divElement.style.top = `${this.mouseY - 80}px`;
				divElement.style.backgroundColor = this.currentColor;

				divElement.appendChild(divContent);
				docBody.appendChild(divElement);
			}
		},
		starHover: function(event){
			this.usedEvent.push(event);
			let docBody = document.querySelector("body"),
				divElement = document.createElement("div"),
				// remove underscores from event
				divContent = document.createTextNode(event.replace(/_/g, ' '));
			divElement.id = "starHover";
			divContent.id = "iconContent";
			divElement.style.left = `${this.mouseX - 70}px`;
			divElement.style.top = `${this.mouseY - 60}px`;
			divElement.appendChild(divContent);
			docBody.appendChild(divElement);
		},
		mouseLocation: function(event){
			this.mouseX = event.clientX,
			this.mouseY = event.clientY;
		}, 
		iconLeave: function(event){
			let removeEvent = event;
			this.usedEvent = this.usedEvent.filter(function(item){
				return item !== removeEvent;
			});
			let hoverElement = document.getElementById("iconHover"), 
				starElement = document.getElementById("starHover");
			if(hoverElement){
				hoverElement.parentNode.removeChild(hoverElement);
			} else if(starElement){
				starElement.parentNode.removeChild(starElement);
			}	
				
		}, 
		iconContent: function(title){ 
			let strippedIcon = title.replace(/[0-9]/g, ''),
				programs = this.$store.state.programs,
				// filter programs, reducing to the object with matching program Title
				filterProgram = programs.reduce((a, o) => (o.programIconName == strippedIcon && a.push(o), a), []),
				filterContent = filterProgram[0].programLabs.reduce((a, o) => (o.fields.mapId == title && a.push(o), a), []);

			this.currentColor = filterProgram[0].programColor;
			this.iconHoverContent = filterContent[0].fields.labTitle;
		}
	}
};
</script>
<style lang="scss">
	// .MapContainer svg g.Manufacturing { display: none }
	.MapContainer{
		height:100vh;
		width: 100vw;
	}
	.MapSvg{
		height: 150vh;
		width:100vw;
		cursor:grab;
	}
	// get rid of gradient on edge
	.cls-12{
		display:none;
	}
	.MapContainer svg g#attractions>path.cls-13:hover{
		fill:#ffc00a !important;
	}
	#iconHover, #starHover{
		position:fixed;
		display:flex;
		flex-direction: column;
		justify-content: center;
		font-weight: bold;
		text-align: center;
		font-size: 11px;
		word-wrap: break-word;
		max-width: 20ch;
	}
	#iconHover{
		min-height: 55px;
		min-width: 155px;
		color: white;
	}
	#starHover{
		min-height: 27px;
		min-width: 162px;
		border-radius: 13.8px;
		background-color: #E6E7E8;
	}

	.MapContainer svg g.Manufacturing, svg g.PoliceScience, svg g.Design, svg g.BusinessIT,svg g.GeneralEd, svg g.Healthcare, svg g.Aviation  {cursor:pointer}

	/* classes for logo display */
	// all displays everything
	.showAviation {
		svg g.Manufacturing { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.Design { display: none; }
		svg g.BusinessIT { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Healthcare { display: none; } 
	}
	.showHealthcare {
		svg g.Manufacturing { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.Design { display: none; }
		svg g.BusinessIT { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Aviation { display: none; } 
	}
	.showManufacturing  {
		svg g.Healthcare { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.Design { display: none; }
		svg g.BusinessIT { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Aviation { display: none; } 
	}
	.showPoliceScience  {
		svg g.Healthcare { display: none; }
		svg g.Manufacturing { display: none; }
		svg g.Design { display: none; }
		svg g.BusinessIT { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Aviation { display: none; } 
	}
	.showDesign  {
		svg g.Healthcare { display: none; }
		svg g.Manufacturing { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.BusinessIT { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Aviation { display: none; } 
	}
	.showBusinessIT  {
		svg g.Healthcare { display: none; }
		svg g.Manufacturing { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.Design { display: none; }
		svg g.GeneralEd { display: none; } 
		svg g.Aviation { display: none; } 
	}
	.showGeneralEd  {
		svg g.Healthcare { display: none; }
		svg g.Manufacturing { display: none; }
		svg g.PoliceScience { display: none; }
		svg g.Design { display: none; }
		svg g.BusinessIT { display: none; } 
		svg g.Aviation { display: none; } 
	}
	

</style>
