import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import appStyle from './css/app.scss';
import vueVimeoPlayer from 'vue-vimeo-player';

import MQ from 'vue-match-media/src';
Vue.use(MQ);


import Pym from 'pym.js';

let pymChild = new Pym.Child();

Vue.prototype.$pym = pymChild;

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faChevronLeft, faChevronRight, faArrowAltCircleRight, faChevronCircleRight, faArrowAltCircleLeft, faChevronCircleLeft, faCircle, faChevronDown, faChevronUp, faQuestionCircle, faTimesCircle, faChevronCircleUp} from '@fortawesome/free-solid-svg-icons';


Vue.config.productionTip = false;

var config = require('../config.json');

Vue.prototype.$wsu = {
	ApiKey: config.ApiKey
};

// add font awesome icon to library
library.add(faChevronLeft, faChevronRight, faArrowAltCircleRight, faChevronCircleRight, faArrowAltCircleLeft, faChevronCircleLeft, faCircle, faChevronDown, faChevronUp, faQuestionCircle, faTimesCircle, faChevronCircleUp);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(vueVimeoPlayer);

Vue.mixin({
	methods: {
		sleep: function(ms){
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		transitionTimer: async function(){
			await this.sleep(2000);
			console.log('mixin timer finished');
		},
	}
});

new Vue({
	router,
	store,
	beforeCreate() {
		this.$store.commit('initialiseStore');
	},
	appStyle,
	mq: {
		phone: '(max-width: 768px)',
		tablet: '(max-width: 1024px)',
		landscape: '(orientation: landscape) and (max-height: 428px)'
	},
	render: h => h(App)
}).$mount('#app');
