<template>
	<div>
		<div class="modal" :class="{'is-active': showModalFlag}">
			<div class="modal-background" ></div>
			<div class="modal-content">
				<div class="columns">
					<div class="column is-4 labModalContent">
						<div class="topContent">
							<div class="stopContent" v-if="!this.$store.state.stop">
								<h2>{{this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labTitle}}</h2>
								<br>
								<p v-if="this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labMarkdown">{{this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labMarkdown.content[0].content[0].value}}</p><br><br>
								<!-- <p v-if="this.$store.state.currentRotation">
									{{`rotation: ${JSON.stringify(this.$store.state.currentRotation)}`}}
								</p>
								<br>
								<p v-if="this.$store.state.currentSweepId">
									{{`sweepID: ${this.$store.state.currentSweepId}`}}
								</p> -->

							</div>
							<div v-if="this.$store.state.stop">
								<MatterportStopContainer :stopVideo="stopVideo" :activeModel="true" />
							</div>
						</div>

						<div class="disclaimer2">
							Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.
						</div>

						<div class="tourButtonContainer">
							<!-- previous buttons -->
							<!-- <font-awesome-icon class="matterportButton" id="previousLabButton " @click="$emit('previousLab');previousLab()" :icon="{ prefix: 'fas', iconName: 'chevron-circle-left' }" size="lg"/> -->
							<font-awesome-icon v-if="this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labStops" class="matterportButton"  id="previousStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-left' }" size="lg"/>

							<!-- *** get matterport sweep id -->
							<!-- <font-awesome-icon class="matterportButton" id="getPoseButton" :icon="{ prefix: 'fas', iconName: 'circle' }" size="lg"/> -->
							<font-awesome-icon @click="$store.commit('seeTourButtonGuide')" class="matterportButton"  :icon="{ prefix: 'fas', iconName: 'question-circle' }" size="lg"/>

							<!-- next buttons -->
							<font-awesome-icon v-if="this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labStops" class="matterportButton"  id="nextStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-right' }" size="lg"/>
							<!-- <font-awesome-icon class="matterportButton"  id="nextLabButton" @click="$emit('nextLab'); nextLab()" :icon="{ prefix: 'fas', iconName: 'chevron-circle-right' }" size="lg"/> -->
						</div>
						<!-- if v-if="this.$store.state.showTourButtonGuide" show TourButtonGuide -->
						<TourButtonGuide v-if="this.$store.state.showTourButtonGuide"/>
					</div>
					<div class="column is-8">
						<div class="MatterportScene">
							<iframe ref="showcase" width="1280" height="640" frameborder="0" allowfullscreen allow="xr-spatial-tracking" id="showcase"></iframe>
						</div>
					</div>
				</div>
			</div>
			<button class="modal-close is-large" aria-label="close" @click="$emit('collapseModal'); closeMatterport()"></button>
		</div>
	</div>
</template>
<script>
import MatterportStopContainer from '@/components/MatterportStopContainer';
import TourButtonGuide from '@/components/TourButtonGuide.vue';
export default {
	props: ["showModalFlag", "currentLab"],
	name: 'GuidedMatterport',
	components: {
		MatterportStopContainer,
		TourButtonGuide
	},
	data: function(){
		return {
			// ensure we don't exceed total lab count
			labsCount: this.$store.state.selectedProgram.programLabs.length,
			// used as index to set the lab

			// used to set the lab stop
			currentStop: -1,
			// the current stop object holding content
			stop: this.$store.state.stop,
			sdk : '',
			stopVideo:false,
			movingToStop: false,
			clickedStop: ''
		};
	},
	methods: {
		closeMatterport:function(){
			this.$store.commit('setStop', '');
			this.currentStop = -1;
			this.stopVideo = !this.stopVideo;
		},
		previousLab: function(){
			// go to previous lab and reload matterport
			if(this.currentLab != 0){
				this.closeMatterport();
				this.$emit('collapseModal');
			}
		},
		nextLab: function(){
			// go to next lab and reload matterport
			if(this.currentLab < this.labsCount){
				this.closeMatterport();
				this.$emit('collapseModal');
			}
		},
		getMatterport: function () {
		// set src with selected program lab, using current lab as index
			this.$refs.showcase.src = 'https://my.matterport.com/show/?m=' + this.$store.state.selectedProgram.programLabs[this.currentLab].fields.labId + '&play=1&applicationKey=x0p2k5ytf567im21u3a1yt39a';


			// ensure we are begining at the first stop
			this.currentStop = -1;
			this.$store.commit('setStop', '');
			let that = this;

			return window.MP_SDK.connect(this.$refs.showcase, this.$wsu.ApiKey, '3.0')
				.then(function(sdk) {
				// function to move user to each stop
					const moveToStop = function(stop){

						that.$store.commit('setStop', stop);

						const sweepId = stop.fields.stopId;
						const rotation = stop.fields.stopRotation;
						const transition = sdk.Sweep.Transition.FLY;
						const transitionTime = 2000; // in milliseconds

						sdk.Sweep.moveTo(sweepId, {
							rotation: rotation,
							transition: transition,
							transitionTime: transitionTime,
						})
							.then(function(sweepId){
								// Move successful.
								console.log('Arrived at sweep ' + sweepId);
							})
							.catch(function(error){
								// Error with moveTo command
								console.error(error);
							});

					};

					// Your Matterport SDK application starts here.
					sdk.Model.getData().then(function(modelData){
						console.log('Model data loaded for sid:', modelData.sid);
					});
					sdk.on(sdk.Camera.Event.MOVE, () => {
						sdk.Camera.getPose()
							.then(function(pose){
								that.$store.commit('setCurrentSweepId', pose.sweep);
								// *** run through labs and select any labs with matching pose.sweep. If this is slow switch to a loop
								let determineStop = that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops.findIndex(stop => stop.fields.stopId == that.$store.state.currentSweepId );

								console.log(`determineStop: ${determineStop}, clickedStop: ${that.clickedStop}, sweepId: ${pose.sweep}, currentSweepId: ${that.$store.state.currentSweepId}`);

								if((determineStop >= 0) && !(that.clickedStop == determineStop)){
									that.clickedStop = determineStop;
									moveToStop(that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops[determineStop]);
									console.log('if statement triggered');
								} else {
									console.log('not triggered ');
								}
							});
					});

					//  previous stop
					document.querySelector('#previousStopButton').addEventListener('click', function(){
						console.log(`Previous stop, currentStop: ${that.currentStop}`);
						if((that.currentStop > 0)){
							that.currentStop--;
							moveToStop(that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops[that.currentStop]);

						} else if(that.currentStop == 0){
							return;
						}
					});

					//  next stop
					document.querySelector('#nextStopButton').addEventListener('click', function(){
						that.currentStop++;
						// update current stop except if the current stop is the last
						if(that.currentStop < (that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops.length)){
							moveToStop(
								that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops[that.currentStop]
							);
						} else {
							that.currentStop = 0;
							moveToStop(that.$store.state.selectedProgram.programLabs[that.currentLab].fields.labStops[that.currentStop]);
						}
					});

					// *** get Pose, uncomment if needing to use
					// document.querySelector('#getPoseButton').addEventListener('click', function(){
					// 		sdk.Camera.getPose()
					// 		.then(function(pose){
					// 			console.log('Current position is ', pose.position);

					// 			console.log('Rotation angle is ', pose.rotation);
					// 			that.$store.commit('setCurrentRotation', pose.rotation);

					// 			console.log('Sweep UUID is ', pose.sweep);
					// 			that.$store.commit('setCurrentSweepId', pose.sweep);

					// 			console.log('View mode is ', pose.mode);
					// 		});
					// 	})

				})
				.catch(function(error) {
					console.error(error);
				});
		},
	}
};

</script>
<style lang="scss" scoped>

.modal-close {
	background-color: rgba(255, 192, 10, .8);
}
.modal-close:hover {
	background-color: #222222;
}
.stopContent{
	padding: 0% 7% 0% 7%;
}

.stopContent :is(h2){
	text-align: left;
}
.stopContent :is(a){
  color: #ffc00a !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

@media screen and (max-width: 1023px) {
	.columns{
		max-width:100vw;
	}
	.top-content, .tourButtonContainer, .MatterportScene{
		width: 100vw;
	}
	.top-content{
		padding: 0px;
	}
}

@media screen and (max-width: 768px) {
	.stopContent{
		padding-top: 0%;
	}
}


</style>
