<template>
	<div class="guidedTour">
		<div class="columns is-gapless is-mobile" style="height:100%; margin-bottom:0rem;">
			<div class="column is-one-third is-hidden-mobile  accordionColumn">
				<div class="accordionContainer">
					<div>
						<GuidedTourAccordion
							title="Click to Begin Tour"
							v-bind:class="{ beginTourHighlight: beginTour, hiddenTab: (!beginTour)}" :beginTourAccordion="beginTour"
							:accordionKey = 0
							:activeProgram = activeProgramIndex
							v-on:next="beginTour = false; nextProgram(programs[0])" />
					</div>
					<div v-for="(program,index) in programs" :key="program.programOrder" >
						<GuidedTourAccordion
							:class="hiddenTab((index + 1))"
							v-on:next="nextProgram(programs[(index + 1)])"
							v-on:previous="previousProgram(index)"
							v-on:openTour="activateGuidedMatterport = true; initiateMatterport()"
							v-if="program.programTitle != 'All'"
							:beginTourAccordion="beginTour"
							:title="program.programTitle"
							:accordionText="program.programAccordionText"
							:icon="program.backgroundIcon"
							:accordionKey="index + 1"
							:activeProgram = activeProgramIndex
						/>
					</div>
				</div>
				<div class="routingLinks" :class="{moveHomeLink: (!beginTour)}">
					<div >
						<a @click="$router.push('/')">Home</a>
					</div>
					<div>
						<a @click="$router.push('/self-tour')"><font-awesome-icon style="margin-top:1vh;" class="chevron selfTourChevron" :icon="{ prefix: 'fas', iconName: 'chevron-right' }"/>
							EXPLORE OUR INTERACTIVE MAP</a>
					</div>
				</div>
			</div>
			<div class="column guidedTourContentColumn">
				<div v-if="beginTour" class="guidedTourContent" >
					<!-- If we haven't selected a program yet -->
					<div class="guidedTourProgramImage">
						<img src="../assets/images/guidedTourHomepage.png"/>
					</div>
					<div class="subImageContent">
						<MobileBreadcrumbs routerColor="black" v-if="isMobile()" />
						<div class="guidedTourTitle">
							<h1>Time to discover life at WSU Tech!</h1>
						</div>
						<p>WSU Tech is a different kind of college, changing lives by training people for high-wage, high-demand jobs. We operate on four campuses across the greater Wichita area, offering more than 100 degree and certificate programs. We work hand-in-hand with employers to ensure our programs are designed so students graduate with the right skills and experience to get their dream job.</p><br>
						<p>During our guided tour, we’ll help you explore popular spots on campus through seven core areas of study: Aviation, Healthcare, Manufacturing in Trades, General Education, Law Enforcement, Design, and Business and IT. Interested in something else? WSU Tech can customize a program track to help you graduate with the degree of your dreams. </p><br>
						<p>Questions? <a class="tourLink" title="link to WSU Tech contact page" href="https://wsutech.edu/admissions/contact/">Let us know and we can point you in the right direction.</a> Let’s get this tour started! </p>
					</div>
				</div>

				<!-- Display contect from selected program -->
				<div v-else-if="!beginTour" class="guidedTourContent" >
					<div v-if="this.$store.state.selectedProgram.programImage" class="guidedTourProgramImage">
						<img :src="this.$store.state.selectedProgram.programImage.fields.file.url"/>
					</div>
					<div class="subImageContent">
						<MobileBreadcrumbs v-if="isMobile()" />
						<div class="guidedTourTitle">
							<h1>{{this.$store.state.selectedProgram.programTitle}}</h1>
						</div>
						<VueMarkdown v-if="!isMobile()" :key="uniqueKey(activeProgramIndex)" :breaks="false">{{this.$store.state.selectedProgram.programGuidedMarkdown}}</VueMarkdown>
						<VueMarkdown v-if="isMobile()" :key="uniqueKey(activeProgramIndex)" :breaks="false">{{this.$store.state.selectedProgram.programGuidedMarkdownMobile}}</VueMarkdown>
						<br>
						<GuidedMatterport v-if="!$mq.tablet && activateGuidedMatterport" :key="uniqueKey(activeProgramIndex)" :currentLab="currentLab" @nextLab="nextLab()" @previousLab="previousLab()" class="guidedMatterport" ref="guidedMatterport" :showModalFlag="activateGuidedMatterport" @collapseModal="closeModal"/>
						<MobileGuidedMatterport v-if="$mq.tablet && activateGuidedMatterport"  :key="uniqueKey(activeProgramIndex)" :currentLab="currentLab" @nextLab="nextLab()" @previousLab="previousLab()" class="mobileGuidedMatterport" ref="guidedMatterport" :showModalFlag="activateGuidedMatterport" @collapseModal="closeModal"/>
					</div>
				</div>
				<br>
			</div>
		</div>
		<div v-if="$mq.phone" class="MobileWayFindingContainer">
			<MobileGuidedTourWayfinding :activeProgramIndex="activeProgramIndex" :beginTour="this.beginTour" @initiate="activateGuidedMatterport = true; initiateMatterport()" @previous="previousProgram(activeProgramIndex)" @next="nextProgram(programs[activeProgramIndex])"/>
		</div>
		<TransitionScreen :showModalFlag="activeTransition"/>
	</div>
</template>

<script>

import MobileBreadcrumbs from '@/components/MobileBreadcrumbs.vue';
import GuidedTourAccordion from '@/components/GuidedTourAccordion.vue';
import GuidedMatterport from '@/components/GuidedMatterport';
import MobileGuidedMatterport from '@/components/MobileGuidedMatterport';
import VueMarkdown from 'vue-markdown';
import MobileGuidedTourWayfinding from '@/components/MobileGuidedTourWayfinding';

import TransitionScreen from '@/components/TransitionScreen';

export default {
	name: 'GuidedTour',
	data: function(){
		return {
			beginTour: true,
			programs: this.$store.state.programs,
			activateGuidedMatterport: false,
			activeProgramIndex: 0,
			currentLab: 0,
			activeTransition: false,
			uniqueIndex: 0
		};
	},
	components: {
		MobileBreadcrumbs,
		GuidedTourAccordion,
		VueMarkdown,
		GuidedMatterport,
		MobileGuidedMatterport,
		MobileGuidedTourWayfinding,
		TransitionScreen
	},
	created: function(){
		this.programs = this.$store.state.programs.filter(function(program){
			return program.programTitle !== "All";
		});
	},
	mounted () {
		this.$pym.sendHeight();
	},
	updated () {
		this.$pym.sendHeight();
	},
	methods: {
		nextLab: function(){
			let that = this;
			this.activeTransition = true;
			this.currentLab++;
			console.log(`currentLab: ${this.currentLab}`);
			this.closeModal();
			this.transitionTimer().then(function(){
				that.activeTransition = false;
				that.activateGuidedMatterport = true;
				that.initiateMatterport();
			});
		},
		previousLab: function(){
			let that = this;
			this.currentLab--;
			this.activeTransition = true;
			this.closeModal();
			this.transitionTimer().then(function(){
				that.activeTransition = false;
				that.activateGuidedMatterport = true;
				that.initiateMatterport();

			});
		},
		nextProgram: function(selection){
			this.uniqueIndex++;
			this.beginTour = false;
			this.currentLab = 0;
			if(this.activeProgramIndex < this.programs.length){
				this.activeProgramIndex++;
			}
			this.$store.commit('selectProgram', selection);
		},
		previousProgram: function(index){
			this.uniqueIndex++;
			if(index == 0){
				this.activeProgramIndex = 0;
				this.beginTour = true;
			} else if(index >= 1){
				this.activeProgramIndex--;
				this.beginTour = false;
				this.currentLab = 0;
				this.$store.commit('selectProgram', this.programs[(index-1)]);
			}
		},
		closeModal() {
			this.uniqueIndex++;
			this.activateGuidedMatterport = false;
		},
		initiateMatterport(){
			this.$nextTick().then(() => this.$refs.guidedMatterport.getMatterport());
		},
		hiddenTab(index){
			if(index >= this.activeProgramIndex)
				return '';
			else{
				return 'hiddenTab';
			}
		},
		isMobile() {
			return this.$mq.phone;
		},
		uniqueKey(program){
			let uniqueKey = `${program}-${this.uniqueIndex}`;
			return uniqueKey;
		}
	}
};
</script>
<style lang="scss">
.guidedTour{
  margin-bottom: -10vh;
}

.accordionColumn{
  max-height: 100vh;
  min-height:100vh;
  background-color: black;
  max-width: 400px;
}
.accordionContainer{
  max-height:85vh;
  overflow-y: hidden;
}
.hiddenTab{
  display: none;
}
.beginTourHighlight{
  background-color: #ffc00a;
  cursor:pointer;
}
.beginTourHighlight :is(h3) {
  color:black !important;
}
.card-header-icon{
  visibility: hidden;
  margin-left: -50px !important;
  justify-content: flex-end;
}
.card-header-title{
  justify-content: flex-end;
}
.chevron{
  font-size:8px;
  margin-bottom: 1px;
}
.selfTourChevron{
    margin-right:0.75em;
}
.routingLinks{
  position:relative;
  top:1%;
  text-align: right !important;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0rem 1rem 0rem 1rem;
}
.routingLinks :is(a){
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 0.75rem;
  color:#ffc00a !important;
  text-decoration: none !important;
}
.routingLinks:hover{
  color: #ffc00a !important;
}
.moveHomeLink{
  top: 1% !important;
}
.guidingButtons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.guidedTourContentColumn{
  max-height: 100vh;
  overflow-y:scroll;
}

.guidedTourColumn{
  display: flex;
  flex-direction: column;
}
.guidedTourTitle{
  display: flex;
  flex-direction: row;
  padding: 2rem 0rem 2rem 0rem;
  text-align: left;
  justify-content:space-between;
}

.guidedTourTitle :is(p){
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75rem;
}
.guidedTourTitle :is(h1){
  line-height:1;
}
.selfTourLink{
  font-weight:bold !important;
  white-space: nowrap;
}
.tourLink{
  color:#ffc00a;
}
.guidedTourProgramImage :is(img){
  width:100%;
}
.gradientStyling{
  position:sticky;
  // top: 102vh;
  z-index:1;
  width:33.3vw;
  height: 30vh;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.7375816537552521) 50%, rgba(255,255,255,0) 100%);
  max-width: 400px;
  bottom: 0% !important;
}
.MobileWayFindingContainer{
  display: none;
}
.subImageContent{
  padding: 0 15%;
}
.subImageContent :is(p){
  text-align: left !important;
  line-height: 1.5;
}
.subImageContent div:nth-child(2) :is(a){
  color:#ffc00a;
}


.hideNextStop{
  visibility: hidden;
}

@media (min-height: 950px){
  .gradientStyling{
    height: 40vh;
  }
}
@media (min-width: 1400px) and (min-height: 900px){
  .guidedTour{
    margin-bottom: -17vh;
  }
}
@media screen and (max-width: 1023px) {

  .selfTourLink{display: none;}
  .guidedTourTitle{
    justify-content: left;
  }
  .subImageContent{
    padding: 0rem 2rem 2rem 2rem;
    overflow-y:scroll;
  }
  .subImageContent :is(p){
    text-align: left;
  }

  .MobileWayFindingContainer{
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    visibility: visible;
    align-items: center;
    position: sticky;
    width: 100%;
    bottom:0%;
    background-color: #8c8989;
  }
  .guidedTour{
    border-radius: 0px;
    font-weight: bold;
    font-size: 0.9rem;
    border-radius:0px !important;
  }
  .changeButton, .mobileRouterButton{
    border-width: 0px !important;
    border-radius:0px;
    background-color: black !important;
    width: 100%;
  }
  .changeButton{
    color: white !important;
  }
  .mobileRouterButton{
    color: #ffc00a !important;
  }
  .changeButtonIcon{
    margin-top: 0vh !important;
  }
  .footerImage{
    display:none;
  }
}
// ipad only
@media (min-width: 768px) and (min-width: 768px){
  .MobileWayFindingContainer{
    margin-bottom: 29%;
  }
  .guidedTour{
    margin-bottom: 0vh;
  }
}
@media screen and (max-width: 767px) {
  .guidedTourTitle :is(h1){
    font-size: 35pt;
  }
  .MobileWayFindingContainer{
    // margin-bottom: 16vh
  }
  .guidedTour{
    margin-bottom: 0vh;
  }
}


</style>
