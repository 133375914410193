<template>
	<div class="home" :style="{ 'min-height': minHeight}">
		<!-- homeTitle is hidden on mobile -->
		<div class="columns is-gapless homeColumns">
			<div class="column ">
				<div class="homeContentColumn">
					<H1 class="homeTitle">Welcome to <br> WSU Tech</H1>
					<!-- GuidedExploreButtons hidden on mobile -->
					<GuidedExploreButtons class="desktopExploreButtons" guidedRoute="/guided-tour" exploreRoute="/self-tour" guidedClassStyle="mr-3" />
					<br>
					<div class="homeMarkdown">
						<!-- mobileHomeTitle is only used on mobile -->
						<H1 class="mobileHomeTitle">Welcome to <br> WSU Tech</H1>
						<p>Click above to discover the spaces where Shocker life happens from the classrooms to the laboratories to all our campus hot spots.
						</p><br>
						<p>There are two ways to virtually explore our campus. You can select “Take the Guided Tour” for a condensed, high level view of everything WSU Tech has to offer. Or you can choose “Explore on Your Own” to discover the WSU Tech campus at your own pace, spending time digging deep into your favorite programs or labs for a more comprehensive view.
						</p><br>
						<p>For either option, we’re <a class="contactLink" title="link to wsu tech contact page" href="https://wsutech.edu/admissions/contact/">here</a> to answer any questions you may have along the way to help you learn more about WSU Tech. Enjoy your tour!
						</p>
					</div>
					<br>
					<!-- mobile button to go to self tour -->
					<GuidedExploreButtons class="mobileGuidedExploreButtons" guidedRoute="/guided-tour" exploreRoute="/self-tour" guidedClassStyle="mb-3" />
				</div>
			</div>
			<div class="column">
				<div class="homeVideoColumn">
          <iframe class="homeVideo" src="https://player.vimeo.com/video/563304859?autoplay=1&muted=1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
					<!-- Hidden on mobile -->
					<!-- <vimeo-player class="video" video-id='563304859' player-height="500" player-width="500" @ready="$pym.sendHeight()"></vimeo-player> -->
					<!-- mobile video is smaller and hidden on desktop -->
					<!-- <vimeo-player class="mobileVideo" video-id='563304859' player-height="333" @ready="$pym.sendHeight()"></vimeo-player> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import GuidedExploreButtons from '@/components/GuidedExploreButtons.vue';
export default {
	name: 'Home',
	components: {
		GuidedExploreButtons
	},
	mounted () {
		this.$pym.sendHeight();
	},
	updated () {
		this.$pym.sendHeight();
	},
	computed: {
		minHeight () {
			if (!this.$mq.phone) {
				return '900px' ;
			} else {
				return '680px';
			}
		}
	}
};
</script>
<style lang="scss" scoped>

.home{
  text-align: left;
}
.homeTitle{
  padding-bottom: 1rem;
  line-height: 1;
}
.homeColumns{
  padding-top: 1.5rem;
  margin-left: 8% !important;
  margin-right: 8% !important;
}
.mobileHomeTitle{
  display:none;
}
.mobileGuidedExploreButtons{
  visibility: hidden;
}
.homeContentColumn{
  margin-right:4vw;
}
.homeVideoColumn{
  display:flex;
  justify-content: center;
}

#player{
  width: 100vw !important;
  height: auto !important;
  resize: both;
}


@media screen and (max-width: 1023px) {
  .home{
    overflow-x: hidden;
  }
  .homeTitle{
    display:none;
  }
  .mobileHomeTitle{
    display: inherit;
    text-align: left;
    margin: 0rem 0rem 1rem 0rem;
  }
  .homeColumns{
    flex-direction: column-reverse;
    display: flex;
  }
  .column{
    display: flex;
    flex-direction: center;
    justify-content: center;
  }

  .desktopExploreButtons{
    display: none;
  }
  .mobileGuidedExploreButtons{
    font-weight: bold;
    border-radius:0px;
    visibility: visible;
    margin-bottom: 2rem;
  }
  .homeContentColumn{
    margin: 0rem;
    text-align:left;
  }
  .homeMarkdown{
    padding: 0rem .45rem 0rem .45rem;
  }
  .homeMarkdown :is(h1){
    line-height: 1;
  }
  .video{
    margin-top: 20px;
  }
  .contactLink{
    color: black;
    text-decoration: underline;
  }
}

</style>
