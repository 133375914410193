import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import SelfTour from '../views/SelfTour';
import GuidedTour from '../views/GuidedTour';
import StandaloneSite from '../views/StandaloneSite';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/self-tour',
		name: 'SelfTour',
		component: SelfTour
	},
	{
		path: '/guided-tour',
		name: 'GuidedTour',
		component: GuidedTour
	}
];

const router = new VueRouter({
	routes
});

export default router;
