<template>
	<div>
		<div class="modal" :class="{'is-active': showModalFlag}">
			<div class="modal-background" ></div>
			<div class="modal-content" style="height: 100px;">
				<div class="loader-wrapper is-active">
					<div class="loader is-loading">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	props: ["showModalFlag"],
	name: 'TransitionScreen'
};

</script>
<style lang="scss" scoped>
.model-content{
    height:100px !important;
}
.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

        .loader {
            height: 80px;
            width: 80px;
            // border:2px solid #FFC317;
        }

    &.is-active {
        opacity: 1;
        z-index: 1;
    }
}


</style>
