<template>
	<div class="TourButtonGuideBackground">
		<div class="TourButtonGuideContainer">
			<div class="tourDialog">
				<h2>{{this.mobileExploreTourDialogs[currentStop]}}</h2>
			</div>
            <div class="disclaimer">
                Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.
            </div>
			<div class="tourButtonContainer">
				<!-- stop buttons -->
				<font-awesome-icon v-if="currentStop == 1" class="matterportButton highlight" id="previousStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-left' }" size="3x"/>
				<font-awesome-icon v-if="currentStop == 1" class="matterportButton highlight" id="nextStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-right' }" size="3x"/>

				<!-- Content button -->
				<font-awesome-icon v-if="currentStop == 2" class="matterportButton highlight" id="contentButton" :icon="{ prefix: 'fas', iconName: 'chevron-circle-up' }" size="3x"/>

				<!-- Close button -->
				<font-awesome-icon v-if="currentStop == 3" class="matterportButton highlight"  id="closebutton" :icon="{ prefix: 'fas', iconName: 'times-circle' }" size="3x"/>
			</div>
			<div>
				<button @click="next()" class="button is-primary guidedExploreButton nextTourButton">Ok</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'TourButtonGuide',
	data: function(){
		return {
			mobileExploreTourDialogs: ["Hold your phone in portrait orientation.", "To go directly to each bonus content location, click these arrows.", "Read content", "Exit the lab"],
			currentStop: 0,
		};
	},
	methods: {
		next: function(){
			let exploreAdjustedLength = this.mobileExploreTourDialogs.length-1;

			if(this.currentStop < exploreAdjustedLength){
				this.currentStop++;
			} else if(this.currentStop >= exploreAdjustedLength){
				this.$store.commit('seenTourButtonGuide');
			}
		}
	}
};

</script>
<style lang="scss" scoped>
.TourButtonGuideBackground{
    position:absolute;
    height: 100vh;
    width:100vw;
    background-color: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    z-index: 10;
}
.TourButtonGuideContainer{
    display: flex;
    justify-content:space-around;
    flex-direction: column;
    background-color: black;
    width: 40vw;
    height: 40vh;
}
.tourDialog{
    width: 100%;
    padding: 0rem 2.5rem;
    line-height: 1;
    text-align: center;
    display:flex;
    justify-content: center;
}
.tourDialog :is(h1){
    text-align:center;
    width:100%;
}
.highlight{
    color:#ffc00a;
    margin-bottom: 0px !important;
    animation: blinkingBackground 1.5s infinite;
}
.hideButtons{
    display:none;
}
.previousLabButton :hover{
    color:#ffd100;
}
.nextTourButton{
    font-weight:bold;
    border-radius: 0px;
}
@keyframes blinkingBackground{
        0%      { color: #ffc00a;}
		50%		{ color: white;}
		100%	{ color: #ffc00a;}
}

@media screen and (max-width: 767px) {
    .TourButtonGuideBackground{
        top:-11vh;
    }
    .tourDialog{
        height:15vh;
        padding: 0rem;
        color:white;
        margin-top: 1.5rem;
    }
    .tourDialog :is(h1){
        font-size: 22pt !important;
    }
    .TourButtonGuideContainer{
        width: 92vw;
        height: 45vh;
        margin-top: 1.5rem;
    }
    .guidedExploreButton{
        margin-bottom: 1.5rem;
    }
}
</style>
