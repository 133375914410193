<template>
	<div>
		<div class="modal" :class="{'is-active': showModalFlag}">
			<div class="modal-background" ></div>
			<div class="modal-content">
				<div class="">
					<div class="MatterportScene">
						<iframe ref="showcase" width="1280" height="640" frameborder="0" allowfullscreen allow="xr-spatial-tracking" id="showcase"></iframe>
						<div v-if="this.showLabModalContent" class="labModalContent">
							<div class="topContent">
								<div class="stopContent" v-if="!this.$store.state.stop">
									<h2>{{this.$store.state.selectedIconLab.labTitle}}</h2>
									<br>
									<p v-if="this.$store.state.selectedIconLab.labMarkdown">{{this.$store.state.selectedIconLab.labMarkdown.content[0].content[0].value}}</p><br><br>
								</div>
								<div v-if="this.$store.state.stop">
									<MatterportStopContainer :stopVideo="stopVideo" :activeModel="showModalFlag" />
								</div>
							</div>
							<div class="closeTopContent">
								<!-- close popup -->
								<font-awesome-icon @click="triggerModalContent()" class="matterportButton" id="" :icon="{ prefix: 'fas', iconName: 'times-circle' }" size="lg"/>
							</div>
						</div>
					</div>
				</div>
				<div class="disclaimer3">
					Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.
				</div>
				<MobileTourButtonGuide v-if="this.$store.state.showTourButtonGuide"/>
				<div  class="tourButtonContainer">
					<!-- close modal -->
					<font-awesome-icon aria-label="close" @click="$emit('collapseModal'); closeMatterport()" class="matterportButton contentControl" id="closeMatterport" :icon="{ prefix: 'fas', iconName: 'times-circle' }" size="lg"/>
					<!-- previous buttons -->
					<font-awesome-icon v-if="showMovementButtons && $store.state.selectedIconLab.labStops.length > 1" class="matterportButton"  id="mobilePreviousStopButton" ref="mobilePreviousStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-left' }" size="lg"/>

					<!-- *** get matterport sweep id -->
					<!-- <font-awesome-icon class="matterportButton" id="getPoseButton" :icon="{ prefix: 'fas', iconName: 'circle' }" size="lg"/> -->
					<font-awesome-icon @click="openTourButtonGuide()" class="matterportButton"  :icon="{ prefix: 'fas', iconName: 'question-circle' }" size="lg"/>

					<!-- next buttons -->
					<font-awesome-icon v-if="showMovementButtons" class="matterportButton"  id="mobileIconNextStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-right' }" size="lg"/>


					<!-- open Modal Content -->
					<font-awesome-icon aria-label="open content" @click="triggerModalContent(); highlightNewContent = false" class="matterportButton" :class="{highlight:highlightNewContent}" id="openContent" :icon="{ prefix: 'fas', iconName: 'chevron-circle-up' }" size="lg"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import MatterportStopContainer from '@/components/MatterportStopContainer';
import MobileTourButtonGuide from '@/components/MobileTourButtonGuide.vue';
export default {
	props: ["showModalFlag"],
	name: 'IconSelectedMobileMatterport',
	components: {
		MatterportStopContainer,
		MobileTourButtonGuide
	},
	data: function(){
		return {
			// used to set the lab stop
			currentStop: -1,
			// the current stop object holding content
			stop: this.$store.state.stop,
			sdk : '',
			stopVideo:false,
			movingToStop: false,
			clickedStop: '',
			showLabModalContent: true,
			highlightNewContent: false,
			preventDoubleTrigger: true
		};
	},
	watch: {
		'$store.state.stop': function(){
			this.highlightNewContent = true;
		}
	},
	computed: {
		showMovementButtons () {
			return (this.$store.state.selectedIconLab.labStops
									&& ((this.$store.state.selectedIconLab.labStops.length > 1)
									|| (this.$store.state.stop == "")));
		}
	},
	methods: {
		openTourButtonGuide: function(){
			this.$store.commit('seeTourButtonGuide');
			console.log("see tour button guide");
		},
		closeMatterport:function(){
			this.currentStop = -1;
			this.stopVideo = !this.stopVideo;
		},
		triggerModalContent: function(){
			this.showLabModalContent = !this.showLabModalContent;
		},
		getMatterport: function () {
		// set src with selected program lab, using current lab as index
			this.$refs.showcase.src = 'https://my.matterport.com/showcase-beta/?m=' + this.$store.state.selectedIconLab.labId + '&play=1&applicationKey=x0p2k5ytf567im21u3a1yt39a';


			// ensure we are begining at the first stop
			this.currentStop = -1;
			this.$store.commit('setStop', '');
			let that = this;

			return window.SHOWCASE_SDK.connect(this.$refs.showcase, this.$wsu.ApiKey, '3.0')
				.then(function(sdk) {
					console.log('SDK Connected!');
					// function to move user to each stop
					const moveToStop = function(stop){
						that.$store.commit('setStop', stop);

						const sweepId = stop.fields.stopId;
						const rotation = stop.fields.stopRotation;
						const transition = sdk.Sweep.Transition.FLY;
						const transitionTime = 2000; // in milliseconds

						console.log(`moving to stop ${that.currentStop}, sweepId ${stop.fields.stopId} `);

						sdk.Sweep.moveTo(sweepId, {
							rotation: rotation,
							transition: transition,
							transitionTime: transitionTime,
						})
							.then(function(){
								console.log('Arrived at sweep ' + sweepId);
								that.preventDoubleTrigger = true;
							})
							.catch(function(error){
								// Error with moveTo command
								console.error(error);
								that.preventDoubleTrigger = true;
							});

					};

					// Your Matterport SDK application starts here.
					sdk.Model.getData().then(function(modelData){
						console.log('Model data loaded for sid:', modelData.sid);
					});
					sdk.on(sdk.Camera.Event.MOVE, () => {
						sdk.Camera.getPose()
							.then(function(pose){
								that.$store.commit('setCurrentSweepId', pose.sweep);
								// run through labs and select any labs with matching pose.sweep. If this is slow switch to a loop
								let determineStop = that.$store.state.selectedIconLab.labStops.findIndex(stop => stop.fields.stopId == that.$store.state.currentSweepId );

								if(determineStop !== -1 && !(that.currentStop == determineStop)){
									that.currentStop = determineStop;

									moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);
								}

							});
					});

					//  previous stop
					if (document.querySelector('#previousStopButton')) {
						document.querySelector('#mobilePreviousStopButton').addEventListener('click', function(){
						// query selector for this and next function triggering twice, preventDoubleTrigger gets around this.
							if(that.preventDoubleTrigger){
								that.preventDoubleTrigger = false;
								console.log(`Previous stop, currentStop: ${that.currentStop}`);
								if(that.currentStop > 0){
									that.currentStop--;
									moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);

								} else if(that.currentStop == 0){
									return;
								}
							}
						});
					}

					//  next stop
					document.querySelector('#mobileIconNextStopButton').addEventListener('click', function(){
						if(that.preventDoubleTrigger){
							that.preventDoubleTrigger = false;
							that.currentStop++;
							// update current stop except if the current stop is the last
							if(that.currentStop < (that.$store.state.selectedIconLab.labStops.length)){
								moveToStop(
									that.$store.state.selectedIconLab.labStops[that.currentStop]
								);
							} else {
								that.currentStop = 0;
								moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);
							}
						}


					});
				})
				.catch(function(error) {
					console.error(error);
				});
		},
	}
};

</script>
<style lang="scss" scoped>
.model-content{
    max-height: 90vh;
}
.topContent{
    z-index: 10;
    position: fixed;
    top: 0%;
    height: 100%;
    width: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	padding-left: 0rem !important;
}

.stopContent{
	padding-top: 40%;
}

.stopContent :is(h2){
	text-align: left;
}
.closeTopContent{
    position: fixed;
    width: 100%;
    z-index: 10;
    top: 86%;
}
.tourButtonContainer{
    position: fixed;
    top: 94%;
    width: 100%;
}
.matterportButton{
    color: white;
}
#openContent{
    margin-left:3rem
}
#closeMatterport{
    margin-right:3rem
}
.highlight{
    color:#ffc00a;
    margin-bottom: 0px !important;
    animation: blinkingBackground 1.5s infinite;
}
@keyframes blinkingBackground{
        0%      { color: #ffc00a;}
		50%		{ color: white;}
		100%	{ color: #ffc00a;}
}


@media screen and (max-width: 1023px) {
	.columns{
		max-width:100vw;
	}
	.top-content, .tourButtonContainer, .MatterportScene{
		width: 100vw;
        height: 90vh;
	}
	.top-content{
		padding: 0px;
	}
}



</style>
