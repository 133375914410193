<template>
	<nav class="breadcrumb" aria-label="breadcrumbs">
		<ul>
			<li><a class="breadcrumbLink" @click="$router.push('/')" :style="style">HOME</a></li>
		</ul>
	</nav>
</template>
<script>
export default {
	props: ["routerColor"],
	name: 'MobileBreadcrumbs',
	computed: {
		style(){
			return 'color: ' + this.routerColor;
		}
	}
};

</script>
<style lang="scss" scoped>
.breadcrumbLink{
    font-size: 0.8em !important;
    font-weight: bold;
    margin-top: 2em;
    margin-bottom: -3em;
}
</style>
