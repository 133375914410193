<template>
	<div class="wayfindingContainer">
		<div class="wayfindingContent" id="mobileTourButtons">
			<button v-if="!beginTour" v-on:click="$emit('previous')" class="button is-large changeButton">
				<font-awesome-icon style="margin-top:1vh;" class="changeButtonIcon chevron" :icon="{ prefix: 'fas', iconName: 'chevron-left', size: 'lg' }"/>
			</button>
			<button  v-if="!beginTour" @click="$emit('initiate')" class="button is-primary mr-3 guidedTour button is-large is-rounded" style="margin-right: 0px !important;">Visit</button>
			<button v-if="!beginTour" v-on:click="$emit('next')" :class="{hideNextStop: (activeProgramIndex == 7)}" class="button is-large changeButton">
				<font-awesome-icon style="margin-top:1vh;" class="changeButtonIcon chevron" :icon="{ prefix: 'fas', iconName: 'chevron-right' }" size="lg"/>
			</button>
			<button v-if="beginTour" v-on:click="$emit('next')" :class="{hideNextStop: (activeProgramIndex == 7)}" class="button is-large changeButton startButton">
				Start
			</button>
		</div>
	</div>

</template>
<script>
export default {
	props: ["beginTour", "activeProgramIndex"],
	name: 'MobileGuidedTourWayfinding'
};

</script>
<style lang="scss" scoped>
    .wayfindingContainer{
        width: 100%;
    }
    #mobileTourButtons{
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: row;
    }
    #wayfinder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .startButton{
       color: #ffc00a !important;
       font-weight: bold;
       font-size: 1.5rem;
    }
</style>
