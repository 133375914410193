<template>
	<div :class="`accordionItem accordionKey-${accordionKey}`" style="border-bottom: 1px solid #ededed;">
		<!-- If user at begin Tour -->
		<div v-if="accordionKey == 0">
			<div class="accordionHeader" v-on:click="nextProgram">
				<h3>
					{{title}}
					<font-awesome-icon style="margin-top:1vh;" class="" :icon="{ prefix: 'fas', iconName: 'chevron-right' }"/>
				</h3>
			</div>
		</div>

		<!-- Else if user has begun tour -->
		<div v-else-if="activeProgram == accordionKey">
			<div class="accordionHeader highlight">
				<h3>{{title}}</h3>
			</div>
			<div v-bind:class="{lastProgram:lastProgramPadding}" class="accordionContent">
				<img class="backgroundIcon" :src="icon.fields.file.url">
				<h4>{{accordionText}}</h4>
				<div class="exploreContainer">
					<button v-on:click="openTour" class="button is-large is-rounded">Visit Lab</button>
				</div>
				<div class="controlButtons">
					<div class="goBack">
						<button class="button programButton is-large is-fullwidth is-black" v-on:click="previousProgram">
							<p>
								<font-awesome-icon style="margin-top:1vh;" class=" chevron chevron-left" :icon="{ prefix: 'fas', iconName: 'chevron-left' }"/>
								GO BACK
							</p>
						</button>
					</div>
					<div class="nextStop">
						<button v-if="!lastProgramPadding" class="button programButton is-large is-fullwidth nextProgram" v-on:click="nextProgram">
							<p>
								NEXT STOP
								<font-awesome-icon style="margin-top:1vh;" class=" chevron chevron-right" :icon="{ prefix: 'fas', iconName: 'chevron-right' }"/>
							</p>
						</button>
						<button v-else class="button programButton is-large is-fullwidth nextProgram" @click="$router.push('/self-tour')">
							<p>
								EXPLORE MORE ON YOUR OWN
								<font-awesome-icon style="margin-top:1vh;" class=" chevron chevron-right" :icon="{ prefix: 'fas', iconName: 'chevron-right' }"/>
							</p>
						</button>
					</div>
				</div>
			</div>
		</div>

		<!-- Else content is hidden -->
		<div v-else>
			<div v-bind:class="{lastProgram:lastProgramPadding}" class="accordionHeader">
				<h3>{{title}}</h3>
			</div>
			<div class="accordionContent isHidden">
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["title", "accordionText", "icon", "beginTourAccordion", "accordionKey", "activeProgram"],
	name: 'GuidedTourAccordion',
	data: function(){
		return {
			lastProgramPadding: false
		};
	},
	created:function() {
		if(this.accordionKey == 7){
			this.lastProgramPadding = true;
		}
	},
	methods: {
		nextProgram : function(){
			this.$emit('next');
		},
		previousProgram: function(){
			this.$emit('previous');
		},
		openTour: function(){
			this.$emit('openTour');
		}
	}
};

</script>
<style lang="scss" scoped>
  .accordionItem{
     border-bottom: 1px solid #ededed !important;
  }
  .accordionHeader{
    margin-bottom: 0rem !important;
  }
  .accordionHeader :is(h3){
    color: white;
    font-weight: bold !important;
    text-align: right;
    padding: 1rem 1.5rem 1rem 0rem;
  }
  .backgroundIcon{
    position: absolute;
    width:262px;
    height:261px;
    left:-9%;
    top:13%;
  }
  .highlight{
    background-color: #ffc00a;
    cursor:pointer;
  }
  .highlight :is(h3) {
    color:black !important;
  }
  .isHidden{
    display: none;
    max-height: 0px
  }
  .active{
    display: contents !important;
    max-height: auto !important;
  }
  .accordionContent :is(h4){
    text-align: left;
    font-weight: bold !important;
    text-transform: uppercase;
    background-color: #ededed;
    color: black;
    padding: 7vh 4vw 4vh 4vw;
    min-height:185px;
    max-height:185px;
  }
  .accordionContent{
    position:relative;
    z-index: 2;
    overflow:hidden;
  }
  .exploreContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: #ededed;
    padding: 0rem 2rem 0rem 0rem;
  }
  .exploreContainer :is(button){
    background-color: #ffc00a;
    font-weight: bold;
    font-size:1.25rem;
    min-height:60px;
    min-width:161px;
    margin-bottom: 2vh;
  }
  .controlButtons{
    display: flex;
    flex-direction: row;
  }
  .chevron-right{
    margin-left:0.75em;
  }
  .chevron-left{
    margin-right:0.75em;
  }
  .goBack, .nextStop{
    width: 50%;
    background-color: #ededed;
  }
  .nextProgram{
    background-color:#9FA1A2;
    color: black;
  }
  .programButton{
    border-radius: 0px;
    font-size: 0.75rem !important;

    min-height: 7vh;
  }
  .programButton :is(p){
    font-weight: bold !important;
  }










  .card{
    border-bottom: 1px solid #ededed !important;
  }
  .card-footer{
    border-bottom: 0px !important;
  }
  .guidingButtons{
    justify-content: flex-end;

  }
  // Change background color of Begin Tour accordion when selected
  .beginTourAccordion.card-active > div:nth-of-type(1){
    background-color: #ffc00a !important;
    color: black !important;
  }
  // change font color when selected
  .beginTourAccordion.card-active > div:nth-of-type(1) > p:nth-of-type(1){
    color: black !important;
  }
  // remove height from dropdown when selected
  .beginTourAccordion > div:nth-of-type(2){
    height: 0px !important;
  }

  .is-rounded{
    padding-left: calc(1em + 0.75em) !important;
    padding-right: calc(1em + 0.75em)!important;
  }

  // @media (min-width: 1500px) {
  //   .backgroundIcon{
  //     position: absolute;
  //     right: 8vw;
  //     top: 2vh;
  //     left: -4vw;
  //   }
  // }
</style>
