<template>
	<div class="stopStyling" v-if="this.$store.state.stop != ''">
		<!-- If there is an image -->
		<div v-if="this.$store.state.stop.fields.stopMedia" class="stopContent">
			<div>
				<img :src="this.$store.state.stop.fields.stopMedia[0].fields.file.url"/>
			</div>
			<br>
			<h2>{{this.$store.state.stop.fields.stopTitle}}</h2>
			<br>
			<p>{{this.$store.state.stop.fields.stopMarkdown.content[0].content[0].value}}</p>
		</div>
		<!-- Else if there is a vimeo video -->
		<div v-else-if="this.$store.state.stop.fields.stopVimeoId && activeModel" class="stopContent">
			<div class="videoWrapper">
				<vimeo-player ref="player" v-if="!$mq.phone" class="video" :video-id="this.$store.state.stop.fields.stopVimeoId" player-width="640" :autoplay="true" @ready="$pym.sendHeight()" ></vimeo-player>
				<vimeo-player ref="player" v-if="$mq.phone" class="mobileVideo"  :video-id="this.$store.state.stop.fields.stopVimeoId" player-width="400" :autoplay="true" @ready="$pym.sendHeight()" ></vimeo-player>
			</div>
			<br>
			<h2>{{this.$store.state.stop.fields.stopTitle}}</h2>
			<br>
			<p>{{this.$store.state.stop.fields.stopMarkdown.content[0].content[0].value}}</p>
		</div>
		<!-- else if there are only a title and markdown for the stop -->
		<div v-else="" class="stopContent">
			<h2>{{this.$store.state.stop.fields.stopTitle}}</h2>
			<br>
			<p>{{this.$store.state.stop.fields.stopMarkdown.content[0].content[0].value}}</p>
		</div>
		<!-- show roation and sweep id to build content -->
		<!-- <p v-if="this.$store.state.currentRotation">
			{{`rotation: ${JSON.stringify(this.$store.state.currentRotation)}`}}
		</p>
		<br>
		<p v-if="this.$store.state.currentSweepId">
			{{`sweepID: ${this.$store.state.currentSweepId}`}}
		</p> -->
	</div>
</template>
<script>

export default {
	props: ["stopVideo", "activeModel"],
	name: "MatterportStopContainer",
	methods: {
		pause () {
			this.$refs.player.pause();
		}
	},
	watch:{
		stopVideo (val, oldVal) {
			if (val !== oldVal){
				this.pause();
			}
		}
	}

};
</script>
<style lang="scss">
.stopContent{
	padding: 25% 7% 0% 7%;

}
.stopContent :is(h2, p){
	text-align: left;
}
.videoWrapper{
	display:flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	iframe {
		width: 100%;
	}
}
@media screen and (max-width: 768px) {
	.stopContent{
		padding-top: 0%;
	}
}

</style>
