import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		count: 1,
		// all the programs from contentful
		programs: [],
		//where is the user currently located in the model
		selectedProgram: {},
		// selfTourSelectedIcon holds the id of the icon last emitted from the MapSvg in MapContainer
		selectedIconLab: {},
		position: {x: 0, y: 0, z: 0},
		//which direction is the user currently facing
		rotation: {x: 0, y: 0},
		// lab is an object that mirrors the format of the lab objects returned from contentful
		stop: {},
		currentRotation: '',
		currentSweepId: '',
		showTourButtonGuide: true,
	},
	mutations: {
		initialiseStore: function(state) {
			// Check if the ID exists
			if(localStorage.getItem('store')) {
				// Replace the state object with the stored item
				this.replaceState(
					Object.assign(state, JSON.parse(localStorage.getItem('store')))
				);
			}
		},
		setPrograms: function (state, payload) {
			state.programs = payload;
		},
		selectProgram: function (state, payload) {
			state.selectedProgram = payload;
		},
		setSelectedIconLab: function(state, payload){
			state.selectedIconLab = payload;
			console.log(`new selectedIconLab.labId, ${JSON.stringify(state.selectedIconLab.labId)} set!`);
		},
		//set the position of the user in the model.  intended to be called
		// when matterport raises events related to moving
		setPosition: function (state, payload) {
			state.position = payload;
		},
		//set the rotation of the user in the model.  intended to be called when
		// matterport raises events related to moving
		setRotation: function (state, payload) {
			state.rotation = payload;
		},
		setStop: function(state, payload){
			state.stop = payload;
		},
		setCurrentRotation: function(state, payload){
			state.currentRotation = payload;
		},
		setCurrentSweepId: function(state, payload){
			state.currentSweepId = payload;
		},
		seenTourButtonGuide: function(state){
			state.showTourButtonGuide = false;
		},
		seeTourButtonGuide: function(state){
			state.showTourButtonGuide = true;
		}
	}
});

