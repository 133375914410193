<template>
	<div>
		<div class="modal" :class="{'is-active': showModalFlag}">
			<div class="modal-background" ></div>
			<div class="modal-content">
				<div class="columns">
					<div class="column is-4 labModalContent">
						<div class="topContent">
							<div class="stopContent" v-if="!this.$store.state.stop">
								<h2 class="iconLabTitle">{{this.$store.state.selectedIconLab.labTitle}}</h2>
								<br>
								<p v-if="this.$store.state.selectedIconLab.labMarkdown">{{this.$store.state.selectedIconLab.labMarkdown.content[0].content[0].value}}</p><br><br>
							</div>
							<div v-if="this.$store.state.stop">
								<MatterportStopContainer :stopVideo="stopVideo" :activeModel="showModalFlag" />
							</div>
						</div>

						<div class="disclaimer3">
							Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.
						</div>

						<div class="tourButtonContainer">
							<!-- previous buttons -->
							<font-awesome-icon v-if="showMovementButtons && $store.state.selectedIconLab.labStops.length > 1" class="matterportButton"  id="previousStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-left' }" size="lg"/>

							<!-- open tour button guide -->
							<font-awesome-icon v-if="showStopButtons"  @click="$store.commit('seeTourButtonGuide')" class="matterportButton"  :icon="{ prefix: 'fas', iconName: 'question-circle' }" size="lg"/>

							<!-- next buttons -->
							<font-awesome-icon v-if="showMovementButtons"  class="matterportButton"  id="nextStopButton" :icon="{ prefix: 'fas', iconName: 'arrow-alt-circle-right' }" size="lg"/>
						</div>
						<TourButtonGuide v-if="this.$store.state.showTourButtonGuide" />
					</div>
					<div class="column is-8">
						<div class="MatterportScene">
							<iframe ref="showcase" width="853" height="480" frameborder="0" allowfullscreen allow="xr-spatial-tracking" id="showcase"></iframe>
						</div>
					</div>
				</div>
			</div>
			<button class="modal-close is-large" aria-label="close" @click="$emit('collapseModal'); closeMatterport()"></button>
		</div>
	</div>
</template>
<script>
import MatterportStopContainer from '@/components/MatterportStopContainer';
import TourButtonGuide from '@/components/TourButtonGuide.vue';


export default {
	props: ["labId", "image", "showModalFlag", "showStopButtons"],
	name: 'IconSelectedMatterport',
	components: {
		MatterportStopContainer,
		TourButtonGuide,
	},
	data: function(){
		return {
			currentStop: -1,
			stopVideo: false,
		};
	},
	computed: {
		showMovementButtons () {
			return (this.$props.showStopButtons
									&& ((this.$store.state.selectedIconLab.labStops.length > 1)
									|| (this.$store.state.stop == "")));
		}
	},
	methods: {
		closeMatterport:function(){
			this.currentStop = -1;
			this.stopVideo = !this.stopVideo;
		},
		previousLab: function(){
			// go to previous lab and reload matterport
			if(this.currentLab != 0){
				this.currentLab--;
				this.getMatterport();
			}
		},
		nextLab: function(){
			// go to next lab and reload matterport
			if(this.currentLab < this.labsCount){
				this.currentLab++;
				this.getMatterport();
			}
		},
		getMatterport: function () {
			this.$refs.showcase.src = 'https://my.matterport.com/show/?m=' + this.$store.state.selectedIconLab.labId + '&play=1&applicationKey=x0p2k5ytf567im21u3a1yt39a';
			this.currentStop = -1;
			this.$store.commit('setStop', '');
			let that = this;

			return window.MP_SDK.connect(this.$refs.showcase, this.$wsu.ApiKey, '3.0')
				.then(function(sdk) {
					console.log("sdk connected");

					// function to move user to each stop
					const moveToStop = function(stop){
						that.$store.commit('setStop', stop);

						const sweepId = stop.fields.stopId;
						const rotation = stop.fields.stopRotation;
						const transition = sdk.Sweep.Transition.FLY;
						const transitionTime = 2000; // in milliseconds

						sdk.Sweep.moveTo(sweepId, {
							rotation: rotation,
							transition: transition,
							transitionTime: transitionTime,
						})
							.then(function(sweepId){
								// Move successful.
								console.log('Arrived at sweep ' + sweepId);
							})
							.catch(function(error){
								// Error with moveTo command
								console.log(error);
							});
					};

					// Your Matterport SDK application starts here.
					sdk.Model.getData().then(function(modelData){
						console.log('Model data loaded for sid:', modelData.sid);
					});
					sdk.on(sdk.Camera.Event.MOVE, () => {
						sdk.Camera.getPose()
							.then(function(pose){
								that.$store.commit('setCurrentSweepId', pose.sweep);
								// run through labs and select any labs with matching pose.sweep. If this is slow switch to a loop
								let determineStop = that.$store.state.selectedIconLab.labStops.findIndex(stop => stop.fields.stopId == that.$store.state.currentSweepId );

								if(determineStop !== -1 && !(that.currentStop == determineStop)){
									that.currentStop = determineStop;

									moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);
								}

							});
					});
					//  previous stop
					if (document.querySelector('#previousStopButton')) {
						document.querySelector('#previousStopButton').addEventListener('click', function(){
							if(that.currentStop != 0){
								that.currentStop--;
								moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);
							} else if(that.currentStop == 0){
								return;
							}
						});						
					}


					//  next stop
					document.querySelector('#nextStopButton').addEventListener('click', function(){
						that.currentStop++;
						// update current stop except if the current stop is the last
						if(that.currentStop < (that.$store.state.selectedIconLab.labStops.length)){
							moveToStop(
								that.$store.state.selectedIconLab.labStops[that.currentStop]
							);
						} else {
							that.currentStop = 0;
							moveToStop(that.$store.state.selectedIconLab.labStops[that.currentStop]);
						}
					});
				})
				.catch(function(error) {
					console.error(error);
				});
		},
	}

};

</script>
<style lang="scss" scope>
.modal-close {
	background-color: rgba(255, 192, 10, .8) !important;
}
.modal-close:hover {
	background-color: #222222  !important;
}

.stopContent{
	padding: 0% 7% 0% 7%;
}
.stopContent :is(p){
	text-align: left;
}
.stopContent :is(a){
  color: #ffc00a !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
.iconLabTitle{
	text-align: left;
}

</style>
