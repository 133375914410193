<template>
	<div class="tabContainer">
		<div class="tabColumns" >
			<!-- single row tab -->
			<div v-for="i in Math.ceil(programs.length / 4)" :key="i" :class="`tabColumn-${i}`" style="width:50%;">  
				<!-- loop through store.programs -->
				<Tab v-for="(program) in programs.slice((i - 1) * 4, i * 4 )" :key="program.programOrder" :program="program" :style="'background-color:#363636;'" >
				</Tab>    
			</div>
		</div>
		<div class="collapseSelfTourContent"  @click="$emit('collapseColumn'); flipCollapseIcon()">
			<font-awesome-icon class="collapseIcon" :icon="{ prefix: 'fas', iconName: collapseIcon }"/>
		</div>
	</div>
</template>
<script>
import Tab from "./Tab.vue";

export default {
	props: ["tabMargin"],
	name: 'RotatedTabs',
	components: {Tab},
	data: function(){
		return {
			programs: this.$store.state.programs,
			textColor: 'color:' + this.$store.state.selectedProgram.programTextColor +  ';',
			collapseIcon: 'chevron-left'
		};
	},
	computed: {
		adjustMargin: function(){
			return 'margin-left: ' + this.tabMargin + 'vh;';
		}
	},
	methods: {
		getPreviousColor: function(programOrder){
			// get the index of each program
			let index = programOrder -1;
			if(index == 0 || index == 4){
				return '';
			}
			return this.programs[index-1].programColor;
		},
		flipCollapseIcon: function(){
			if(this.collapseIcon == 'chevron-left'){
				this.collapseIcon = 'chevron-right';
			} else {
				this.collapseIcon = 'chevron-left';
			}
		}
        
	},
    
};

</script>
<style lang="scss" scoped>
    .tabContainer{
        display:flex;
        flex-direction:column;
        align-self: center;
        align-content: baseline;
        z-index: 10;
        position: relative;
        height: 100%;
        width:100%;
    }
    .tabColumns{
        display:flex;
        flex-direction: row;
        height:94vh;
        justify-content: flex-end;
    }
    .tabColumn-2{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .tabColumn{
        display:flex;
        flex-direction: column;
        width:50%;
    }
    
    #collapseTab :is(a){
        color: black !important;
    }

    .collapseSelfTourContent{
        display:flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items:center;
        // position: absolute; 
        // top: 47vh;
        // right: 42.3%; 
        width: 100%; 
        height: 6vh; 
        border-radius: 0px;
        background-color: white;
        z-index:5;
        font-size: 2em;
    }
    .collapseIcon{
        margin-right:20px;
    }

    .collapseSelfTourContent:hover{
        .collapseIcon{
             color: #ffd100;
        }
    }

</style>