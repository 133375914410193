<template>
	<div v-if="this.$store.state.selectedProgram.programTitle !='All'" class="labs">
		<h2>Labs</h2>
		<div>
			<div class="labList" v-for="lab in this.$store.state.selectedProgram.programLabs" :key="lab.fields.labtitle">
				<h3 @click="selectLab(lab.fields)">{{lab.fields.labTitle}}</h3>
			</div>
		</div>
	</div>
</template>
<script>


export default {
	name: 'SelfTourMobileLabList',
	methods: {
		selectLab: function(selection){
			this.$store.commit('setSelectedIconLab', selection);
			this.$emit("openMatterport");
			return;
		}
	},

};

</script>
<style lang="scss" scoped>
.labList :is(h3){
  text-decoration: underline;
  cursor: pointer;
}

</style>
