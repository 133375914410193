<template>
<div>
    <div class="iframeContainer" id="iframeWrapper" style="height: 100%;min-height: 100vh;">
        <iframe src="https://production.virtualtour.wsutech.support/#/" class="siteIframe" style="position: absolute; height: 100%; border: none; top:0;"></iframe>
    </div>
</div>
</template>

<script>


export default {
    name: 'StandaloneSite',
};
</script>
<style lang="scss" scoped>
    .iframeContainer{
        width: calc(100%);
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .siteIframe{
        width: 100%;
        height: 100%;
        max-width: 1440px;
    }
</style>
