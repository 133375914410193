<template>
	<div>
		<bulma-accordion icon="custom"
			class="va-row selfTourMobileAccordion" :key="componentKey">
			<div v-for="(program, index) in this.$store.state.programs" v-bind:key="index" class="flex xs12 no-padding">
				<bulma-accordion-item class="accordionItem" v-if="program.programTitle !== 'All'">
					<span slot="title">
						<div class="flex xs12 accordionHeight">
							<h3>{{program.programTitle}}</h3>
						</div>
					</span>
					<i slot="icon-closed" class="material-icons">
						<font-awesome-icon aria-label="open content" :icon="{ prefix: 'fas', iconName: 'chevron-down' }"/>
					</i>
					<i slot="icon-open" class="material-icons">
						<font-awesome-icon aria-label="open content" :icon="{ prefix: 'fas', iconName: 'chevron-up' }"/>
					</i>
					<div slot="content" :class="program.programTitle + 'content'">
						<h4 @click="selectLab(lab.fields)" v-for="(lab, index) in program.programLabs" v-bind:key="index">{{lab.fields.labTitle}}</h4>
					</div>
					<div slot="footer"></div>
				</bulma-accordion-item>
			</div>
		</bulma-accordion>
	</div>
</template>
<script>
import { BulmaAccordion, BulmaAccordionItem } from 'vue-bulma-accordion';

export default {
	name: 'SelfTourMobileAccordion',
	components: {
		BulmaAccordion,
		BulmaAccordionItem
	},
	data: function(){
		return {
			isActive: false,
			programs: this.$store.state.programs,
			componentKey: 0
		};
	},
	methods: {
		selectLab: function(selection){
			this.$store.commit('setSelectedIconLab', selection);
			this.$emit("openMatterport");
			this.componentKey++;
			return;
		}
	},

};

</script>
<style lang="scss">
    .selfTourMobileAccordion{
        padding: 1.5rem;
				h3 {
					transform: translateX(-1.5em);
				}
    }
    .card-header{
        background-color: #ffc00a !important;
        border-top: 1px solid #000000 !important;
    }
    .card-header-title{
        padding: 1.5rem 0rem 1.5rem 1.5rem !important;
        justify-content: center !important;
        margin:0rem !important;
    }
    .card-header-icon{
        visibility: visible !important;
        color: black;
        margin-left: -15px !important;
    }
    .card-content{
        text-align: left !important;
        text-decoration: underline;
    }
    .accordionHeight :is(h3){
        color: black;
        font-weight: bold !important;
        text-align: center;
        max-width: 150px;
    }
    .card{
        background-color: black !important;
        color: white !important;
    }
    .card-footer{
        border-top: 0px solid #000000 !important;
    }

</style>
