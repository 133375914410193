<template>
	<div class="selfTour">
		<IconSelectedMatterportVue v-if="!isMobile()" :key="selectedLab" ref="matterport" class="iconSelectedMatterport" :showModalFlag="activateModal" @collapseModal="closeModal" :showStopButtons="showStopButtons"/>
		<IconSelectedMobileMatterport v-if="isMobile()"
			:key="selectedLab" class="iconSelectedMobileMatterport" ref="matterport" :showModalFlag="activateModal" @collapseModal="closeModal"  />


		<div class="columns selfTourColumns is-gapless">
			<!-- this column will need to have a visability collapse function working -->
			<div v-if="seeProgramContent" class="column is-one-third programContentColumn">
				<div class="selfTourContent">
					<div class="selfTourContentHeader">
						<MobileBreadcrumbs routerColor="#ffc00a" style="margin-left: 4rem;"/>
						<h1 v-if="this.$store.state.selectedProgram.programTitle == 'All'">Ready to explore?</h1>
						<h1 v-else>{{this.$store.state.selectedProgram.programTitle}}</h1>
					</div>
          
					<!-- selfTourMarkdown is displayed on desktop, hidden on mobile -->
					<VueMarkdown v-if="!$mq.phone" class="tourMarkdown" id="selfTourMarkdown" :key="programMarkdown">{{programMarkdown + '&#60;br&#62;&#60;br&#62;&#60;br&#62;&#60;p class="disclaimer1"&#62;Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.&#60;/p&#62;'}}</VueMarkdown>
          
          <!-- mobileSelfTourMarkdown is hidden on desktop, displayed on mobile -->
					<VueMarkdown v-if="$mq.phone" class="tourMarkdown" id="mobileSelfTourMarkdown" :key="programMarkdownMobile">{{programMarkdownMobile + '&#60;br&#62;&#60;br&#62;&#60;br&#62;&#60;p class="disclaimer1"&#62;Once you have explored all the bonus content in this tour stop, you can return back to the tour homepage by clicking the X in the top right corner.&#60;/p&#62;'}}</VueMarkdown>

					<SelfTourMobileAccordion v-if="isMobile()" v-on:openMatterport="openMatterport()"/>

					<!-- mobile only list of labs for the selected program, open materport w/ emit -->
					<SelfTourMobileLabList v-on:openMatterport="openMatterport()" />
				</div>
			</div>
			<!-- Link displayed over map -->
			<div class="tourMapLink" v-if='false'>
				<a @click="$router.push('/guided-tour')">Take the Guided Tour  <font-awesome-icon class="chevron" :icon="{ prefix: 'fas', iconName: 'chevron-right' }"/></a>
			</div>
			<!-- RotatedTabs no display on mobile.  -->
			<RotatedTabs v-if="!isMobile()" class="column is-1 rotatedTabsColumn" @collapseColumn="collapseProgramColumn" :tabMargin="this.rotatedMargin" />
			<!-- Map, no display on mobile -->
			<div v-if="!isMobile()" class="selfTourMap">
				<MapContainer />
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import MobileBreadcrumbs from '@/components/MobileBreadcrumbs.vue';
import RotatedTabs from '@/components/RotatedTabs.vue';
import MapContainer from '@/components/MapContainer.vue';
import IconSelectedMatterportVue from '@/components/IconSelectedMatterport.vue';
import VueMarkdown from 'vue-markdown';
import SelfTourMobileLabList from '@/components/SelfTourMobileLabList.vue';
import IconSelectedMobileMatterport from '@/components/IconSelectedMobileMatterport';
import SelfTourMobileAccordion from '@/components/SelfTourMobileAccordion';

export default {
	name: 'SelfTour',
	components: {
		MobileBreadcrumbs,
		RotatedTabs,
		MapContainer,
		IconSelectedMatterportVue,
		VueMarkdown,
		SelfTourMobileLabList,
		IconSelectedMobileMatterport,
		SelfTourMobileAccordion
	},
	data: function(){
		return {
			seeProgramContent: true,
			rotatedMargin: "-6.25",
			activateModal: false,
			programMarkdown: this.$store.state.selectedProgram.programExploreMarkdown,
			programMarkdownMobile:this.$store.state.selectedProgram.programExploreMarkdownMobile,
			showStopButtons: false,
			selectedLab: 0,
		};
	},
	watch: {
		'$store.state.selectedIconLab': function(){
			if(this.$store.state.selectedIconLab.labStops){
				this.showStopButtons = true;
			}
			this.$refs.matterport.getMatterport();
			this.activateModal = true;
		},
		'$store.state.selectedProgram.programExploreMarkdown': function(){
			this.programMarkdown = this.$store.state.selectedProgram.programExploreMarkdown;
		},
		'$store.state.selectedProgram.programExploreMarkdownMobile': function(){
			this.programMarkdownMobile = this.$store.state.selectedProgram.programExploreMarkdownMobile;
		}

	},
	created: function(){
		// select the All program when loaded
		let findAll = this.$store.state.programs.find(program => program.programTitle == "All");
		this.$store.commit('selectProgram', findAll);
	},
	mounted () {
		this.$pym.sendHeight();
	},
	updated () {
		this.$pym.sendHeight();
	},
	methods: {
		collapseProgramColumn: function(){
			this.seeProgramContent = !this.seeProgramContent;
			if(this.seeProgramContent){
				this.rotatedMargin = "-6.25";
			} else{
				this.rotatedMargin = "-5.25";
			}
		},
		closeModal() {
			this.activateModal = false;
			this.showStopButtons = false;
			this.selectedLab++;
			console.log(this.selectedLab);
		},
		openMatterport(){
			this.activateModal = true;
			this.$nextTick().then(() => this.$refs.matterport.getMatterport());
		},
		isMobile() {
			return this.$mq.phone;
		}
  }
};
</script>

<style lang="scss" scoped>
.selfTour{
  overflow:hidden;
}
.selfTourColumns{
  max-height: 101vh  !important;
  min-height: 99vh;
  margin-bottom:0px !important;
}
.programContentColumn{
  z-index: 5;
  overflow-y:scroll;
  overflow-x:hidden;
}
.programContentColumn, .mapContentColumn{
  padding: 0rem;
}
.selfTourContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height:100vh;
  background-color: #000;
}
.selfTourContent :is(h1, .tourMarkdown){
  text-align: left;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.selfTourContent :is(h1,.tourMarkdown, .guidedTourLink){
  padding: 1.5rem !important;
}
.selfTourContentHeader{
  padding-top: 1.5rem;
  margin-right: 1.5rem;
}
.selfTourContentHeader :is(h1){
  width: 100%;
  color: #fff;
  line-height: 1;
}
.labs{
  display:none;
}
.contentLogoCircle {
  position:relative;
  top: 3vh;
  left: 3vh;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  background-color: #D8D8D8;
  display: flex;
  justify-content: center;
  margin: 1.5rem, 0rem, 0rem, 1.5rem;
}
.tourMarkdown{
  flex-grow:2;
  color: #fff;
  overflow-y:scroll;
  line-height:1.5;
}
.tourMarkdown :is(a){
  color: #ffc00a !important;
  font-weight: bold !important;
  text-decoration: none !important;
}
#mobileSelfTourMarkdown{
  display:none;
}
.guidedTourLink{
  text-align: right !important;
  margin-bottom:2rem;
}
.guidedTourLink :is(a){
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 0.75rem;
  color:#ffc00a !important;
  padding-right: 2.5rem !important;
  text-decoration: none !important;
}
.guidedTourLink:is(a):hover{
  color:white;
}
.guidedTourLinkChevron{
  font-weight:bold;
}
.mobileGuidedTourLink{
  display:none;
}
.tourMapLink{
  position: absolute;
  left: 86vw;
  top: 0;
  height: auto;
  width: 155px;
  background-color: #ffc00a;
  cursor:pointer;
  z-index:1;
}
.tourMapLink :is(a){
  color:black;
  font-weight: bold;
}
.selfTourMap{
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
  position:relative;
  right: 29.5%;
  margin-top: -8%;
}

.iconSelectedMobileMatterport{
  display:none;
}
@media  (min-width: 1500px){
      .tourMapLink{
        left: 76%;
      }
}

@media screen and (max-width: 1023px) {
  .selfTour{
    overflow-y:scroll;
  }
  .tourMapLink{
    display:none;
  }
  .selfTourContentHeader{
    margin-right: 1.5rem;
  }
  .selfTourContentHeader :is(h1){
    word-wrap:break-word;

    font-size:12vw !important;
  }
  #selfTourMarkdown{
    display:none;
  }
  #mobileSelfTourMarkdown{
    display:inherit;
    display: flex;
    flex-direction: column;
  }
  .rotatedTabsColumn{
    display:none;
  }
  .labs{
    display:flex;
    flex-direction: column;
    justify-content: center;
    color:white;
    margin-bottom: 3rem;
    padding: 0rem 1.5rem 0rem 1.5rem;
  }
  .selfTourMap{
    display: none;
  }
  .guidedTourLink{
    display:none;
  }
  .mobileGuidedTourLink{
    display:flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .mobileGuidedTourLink :is(a){
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75rem;
    color:white;
  }
  .iconSelectedMobileMatterport{
    display:inherit;
  }
}




</style>
