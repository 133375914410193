<template>
	<div class="guidedExploreButtons">
		<button @click="$router.push(guidedRoute)" class="button is-primary guidedExploreButton" :class="guidedClassStyle">Take the guided tour</button>
		<button @click="$router.push(exploreRoute)" class="button is-dark is-outlined guidedExploreButton" :class="exploreClassStyle">Explore on your own</button>
	</div>
</template>
<script>
export default {
	name: 'GuidedExploreButtons',
	// props to control routes, and styling
	props: ["guidedRoute", "exploreRoute", "guidedClassStyle", "exploreClassStyle"]
};
</script>
<style lang="scss">

.guidedExploreButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0rem;
}

.guidedExploreButtons button{
  border-radius: 0px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.9rem;

}

.guidedExploreButton {
  padding: 2em !important;
  font-size: 0.8rem !important;
}
.guidedExploreButton:hover{
  background-color:#363636 !important;
  color:white !important;
}

@media screen and (max-width: 1023px) {
  .guidedExploreButtons {
    justify-content: space-evenly;
    flex-direction: column;
  }
}
</style>
