<template>
  <div id="app">

    <router-view v-if="!$mq.landscape" />
		<div v-if="$mq.landscape">
			<br />
			<h1>Landscape mode not supported.</h1>
			<p>Please use this application in portrait mode for best results.</p>
			<br />
		</div>

  </div>
</template>
<script>
import _ from 'lodash';
import store from './store';

export default {
	store,
	mounted: function () {
      // setInterval(() => {
      //   this.$pym.sendHeight();
      // }, 1000);

		// Subscribe to store updates
		store.subscribe((mutation, state) => {
			// Store the state object as a JSON string
			localStorage.setItem('store', JSON.stringify(state));
		});

		// get programLab from contentful
		// var config = require('../../config.json'),
		var contentful = require('contentful'),
			contentfulConfig = require('../.contentful.json'),
			client = contentful.createClient({
				space: contentfulConfig.CTF_SPACE_ID,
				accessToken: contentfulConfig.CTF_CDA_ACCESS_TOKEN
			});

		// Getting all program enteries into vuex store
		client.getEntries({
			content_type: 'programArea',
			include: 4
		})
			.then((res) => {
				let programs = [];
				res.items.forEach((res) => {
					// Ensure we only select programs
					if(res.fields.programTitle){
						programs.push(res.fields);
					}
					// Make All default program
					if(res.fields.programTitle == "All"){
						this.$store.commit('selectProgram', res.fields);
					}
				});
				this.$store.commit('setPrograms',  _.orderBy(programs, 'programOrder'));
			});
	}
};
</script>



<style lang="scss">


/* prestyling */
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
