<template>
	<div :class="`tab tab-${program.programOrder}`">
		<div class="tabContainer" v-bind:class="{activeTab: (this.$store.state.selectedProgram.programOrder == program.programOrder)}">
			<a class="tabLink" @click="selectProgram(program)">
				<div class="rotatedContent">
					<div class="tabLogoCircle" :style="`border: 1px solid ${program.programColor}`">
						<img class="tabLogo"  :src="program.programIcon.fields.file.url"/>
					</div>
					<div class="programTitle">
						<p style="font-weight: bold">{{program.programTitle}}</p>
					</div>
				</div>
			</a>
		</div>
	</div>
</template>
<script>
export default {
	props: ["program"],
	name: 'RotatedTabs',
	data: function(){
		return {
			programs: this.$store.state.programs,
			activeProgram: "8"
		};
	},
	methods: {
		selectProgram: function(selection){
			this.$store.commit('selectProgram', selection);
		},
	}
};

</script>
<style lang="scss" scoped>
    .tab{
        height:25%;
        background-color: #363636 !important;
    }
    .tab-5, .tab-6, .tab-7, .tab-8{
         height:23.5%;
    }
    .iconlessTab{
        flex-basis:20% !important;
    }
    .tabContainer{
        border-radius: 0px 10px 0 0 !important;
         border-left: 0.5px solid #fff !important;
        border-top: 0.5px solid #fff !important;
        height: 100%;
        background-color: #363636;
    }
    .tabLink{
        justify-content: flex-start !important;
        text-decoration: none !important;
    }
    .rotatedContent{
        height:100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .activeTab{
        background-color:black;
    }
    .tab-1, .tab-5{
        background-color: transparent !important;
    }
    .tab-8{
       .tabLogoCircle{
           opacity: 0%;
       }
    }
    .tabLogoCircle {
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: inline-block;
        background-color: #D8D8D8;
        display: flex;
        justify-content: center;
        transform: rotate(90deg);
        margin: 15px 0px 35px 0px;
    }
    .tabLogo{
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        transform: rotate(-90deg);
        max-width: 17px
    }
    .programTitle{
        // font weight is set inline for this class
        text-transform: uppercase;
        font-size:12px;
        transform: rotate(90deg);
        color:white;
    }
</style>
